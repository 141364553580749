$typeface-regular: 'GTSectraFineTrial-Regular';
$typeface-blod: ' GTSectraFineTrial-Bold';
$typeface-italic: 'GTSectraTrialBlack-Italic';

$grey: #F4F4F4;
$black: #343434;

//Responsive
$iphoneSmall: 320px;
$iphone: 375px;
$iphonePlus: 414px;
$ipad: 768px;
$ipadPro: 1024px;
$ipadLandscape: 1366px;
$desktop: 1440px;

@mixin responsive($width) {
  @if $width == desktop {
    @media only screen and (max-width: $desktop) { @content; }
  }
  @if $width == ipadLandscape {
    @media only screen and (max-width: $ipadLandscape) { @content; }
  }
  @else if $width == ipadPro {
    @media only screen and (max-width: $ipadPro) { @content; }
  }
  @else if $width == ipad {
    @media only screen and (max-width: $ipad) { @content; }
  }
  @else if $width == iphonePlus {
    @media only screen and (max-width: $iphonePlus) { @content; }
  }
  @else if $width == iphone {
    @media only screen and (max-width: $iphone) { @content; }
  }
  @else if $width == iphoneSmall {
    @media only screen and (max-width: $iphoneSmall) { @content; }
  }
  // Non-Retina
  @else if $width == non-retina {
    @media screen and (-webkit-max-device-pixel-ratio: 1) { @content; }
  }
  // Retina Only
  @else if $width == retina {
    @media screen and (-webkit-min-device-pixel-ratio: 2) { @content; }
  }
  // Retina Only
  @else if $width == retina3 {
    @media screen and (-webkit-min-device-pixel-ratio: 3) { @content; }
  }
  // Specific max width
  @else {
    @media only screen and (max-width: #{$width}px) { @content; }
  }
}


//Y axis : @include centre(y);
//X axis : @include centre(x);
//Both axis : @include centre;

@mixin centre($axis: "both"){
    position:absolute;
    @if $axis == "y"{
        top:50%;
        @include translate(0%, -50%);
    }
    @if $axis == "x"{
        left:50%;
        @include translate(-50%, 0%);
    }
    @if $axis == "both"{
        top:50%;
        left:50%;
        @include translate(-50%, -50%);
    }
}

.visible_ipadPro{
  display: none;
  @include responsive(ipadPro) {
      display: block !important;
  }
}

.visible_ipad{
  display: none;
  @include responsive(ipad) {
      display: inherit !important;
  }
}

.visible_iphone{
  display: none;
  @include responsive(iphone) {
      display: block !important;
  }
}

.visible_iphoneSmall{
  display: none;
  @include responsive(iphoneSmall) {
      display: block !important;
  }
}


.hidden_ipadPro {
    @include responsive(ipadPro) {
        display: none !important;
    }
}

.hidden_ipad {
    @include responsive(ipad) {
        display: none !important;
    }
}

.hidden_iphone {
    @include responsive(iphone) {
        display: none !important;
    }
}

.hidden_iphoneSmall {
    @include responsive(iphoneSmall) {
        display: none !important;
    }
}
