@import 'variables.scss';

body {
  background-color: $grey;
}

::selection {
  background: $black;
  color: $grey;
}
::-moz-selection {
  background: $black;
  color: $grey;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 95vh;


  .links {
    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
